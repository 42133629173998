import React from 'react';
import axios from 'axios';

import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { prefixer } from 'stylis';

import logo from './assets/logo.svg';
import banner2 from './assets/banner2.jpg';

import { Container, Box, Stack, Typography, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, CircularProgress, Snackbar, Alert } from '@mui/material';

interface IFormObj {
	interviewType: string;
	interviewerName: string;
	applicantName: string;
	applicantNumber: string;

	leadership: string;
	leadershipDetails: string;
	businessOrientaion: string;
	businessOrientaionDetails: string;
	skills: string;
	skillsDetails: string;
	globalOrientaion: string;
	globalOrientaionDetails: string;
	relationships: string;
	relationshipsDetails: string;

	relevantImpressions: string;
	evaluation: string;

	evaluationPros: string;
	evaluationCons: string;
	recommendation: string;
	// isRelated: string;
	isExcel: string;

	englishVocabulary: string;
	englishGrammar: string;

	englishQuestion: string;
	impressionOfInterview: string;

	impressionRank: string;

	specialRequests: string;

	advisoryMatchB: string;
	advisoryMatchDetailsB: string;
	businessOrientaionB: string;
	businessOrientaionDetailsB: string;
	skillsB: string;
	skillsDetailsB: string;
	leadershipB: string;
	leadershipDetailsB: string;
	relationshipsB: string;
	relationshipsDetailsB: string;
	globalOrientaionB: string;
	globalOrientaionDetailsB: string;

	relevantImpressionsB: string;
}

interface FieldProps {
	formObj: IFormObj;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	fieldKey: keyof IFormObj;
	allTitles: IFormObj;
	errorsObj: Partial<IFormObj>;
	detailsFieldKey?: keyof IFormObj;
	fieldType?: 'text' | 'rank' | 'multiline';
}

interface snackbarDataProps {
	open: boolean;
	severity: 'error' | 'success';
	msg?: string;
}

const cacheLtr = createCache({
	key: 'muiltr',
});

const cacheRtl = createCache({
	key: 'muirtl',
	stylisPlugins: [prefixer, rtlPlugin],
});

const themeComponents = {
	MuiFormLabel: {
		styleOverrides: {
			root: {
				'&.Mui-error': {
					fontWeight: 'bold',
				},
			},
		},
	},
	MuiTextField: {
		styleOverrides: {
			root: {
				'& .MuiOutlinedInput-root.Mui-error': {
					'& fieldset': {
						borderWidth: '2px',
					},
				},
			},
		},
	},
};

const ltrTheme = createTheme({ direction: 'ltr', components: themeComponents });
const rtlTheme = createTheme({ direction: 'rtl', components: themeComponents });

const allTitles: IFormObj = {
	interviewType: 'סמן',
	interviewerName: 'שם המראיין/ת',
	applicantName: 'שם המועמד/ת',
	applicantNumber: 'טלפון נייד של המועמד/ת',

	// leadership: 'כושר מנהיגות - אסרטיביות, יכולת הובלה, יכולת עבודה עצמאית, ערכיות, קבלת ביקורת וסמכות, קבלת מרות, גמישות בינאישית, חוסן פנימי, זיהוי פוטנציאל ניהולי',
	leadership: 'כושר מנהיגות - עצמאות, חוסן נפשי, פרואקטיביות/הובלה, ייצוגיות, יכולת השפעה, יכולת תכלול, גמישות',
	leadershipDetails: 'פירוט',
	// businessOrientaion: 'אוריינטציה עסקית  - הבנת העולם העיסקי, חשיבה אנליטית, יכולות ניתוח, יכולת יצירת קשרים, יוזמה, יכולת קבלת החלטות',
	businessOrientaion: 'אוריינטציה עסקית - חשיבה אנליטית, הבחנה בין עיקר לטפל, ראייה מערכתית',
	businessOrientaionDetails: 'פירוט',
	// skills: 'יכולות מקצועיות - יכולות למידה, יסודיות, שיטתיות, אחריות, עמידה בלחצים, הבחנה בין עיקר לתפל , שימוש בידע באופן רוחבי, יצירת ידע ושיתופו',
	skills: 'יכולות מקצועיות  - מוטיבציה, זריזות/ביצועיות, יכולת העמקה, עמידה בלחץ/עומס, למידה ואוטודידקטיות, סקרנות, יכולת העברת מסר',
	skillsDetails: 'פירוט',
	// globalOrientaion: 'אוריינטציה גלובלית - יצירת קשרים גלובליים, שפות, פרספקטיבה גלובלית, עבודה בסביבות מגוונות ו/או משתנות',
	globalOrientaion: 'אוריינטציה גלובלית - שליטה בשפות, הסתגלות לסביבה משתנה ומגוונת',
	globalOrientaionDetails: 'פירוט',
	// relationships: 'מערכות יחסים - יכולת השפעה ושכנוע, יכולת יצירת קשרים בלתי פורמליים, שירותיות, יחסי אנוש, יכולת עבודה בצוות, פתיחות וקבלת האחר, לויאליות',
	relationships: 'מערכות יחסים - תקשורת בינאישית, שירותיות, הבנה ארגונית',
	relationshipsDetails: 'פירוט',

	relevantImpressions: 'התרשמות מדגשים רלוונטים - (יציבות, התמודדות עם לחץ ושינויים מתמידים,שירותיות, אוטודידקטיות, יכולת השפעה ללא סמכות, התמודדות עם משימות רוטיניות לאורך זמן, יכולת קבלת סמכות וביקורת, אוריינטציה עסקית למשל, קריאת עיתונות כלכלית)',
	evaluation: 'הערכה מסכמת',
	evaluationPros: 'התרשמות חיובית',
	evaluationCons: 'נושאים לשיפור',
	recommendation: 'המלצה',
	// isRelated: 'האם המועמד קשור ללקוח / חברה / קרוב משפחה של עובד?',
	isExcel: 'האם המועמד/ת מומלצ/ת להשתתפות בתוכנית מצטיינים?',

	englishVocabulary: 'אנגלית - אוצר מילים',
	englishGrammar: 'אנגלית - יכולת דקדוק',

	englishQuestion: 'מענה על שאלה באנגלית בכתב במהלך הראיון',
	impressionOfInterview: 'סיכום ראיון - התרשמות לגבי המועמד',

	impressionRank: 'סיכום כללי - התרשמות לגבי המועמד',

	specialRequests: 'בקשות מועמד לגבי שיבוץ לאשכול/מחלקה',

	advisoryMatchB: 'התאמה לפרופיל יועץ - יכולות פרזנטטיביות, חדשנות, יכולת עבודה עצמית, אוטודידקטיות, סדר וארגון, חשיבה מחוץ לקופסא, יוזמה, יצירתיות',
	advisoryMatchDetailsB: 'פירוט',
	// businessOrientaionB: 'אוריינטציה עסקית - זיקה לעולם העסקי, התעניינות בעולמות התוכן העסקיים והפיננסיים, סקרנות ומוטיבציה',
	businessOrientaionB: 'אוריינטציה עסקית - חשיבה אנליטית, הבחנה בין עיקר לטפל, ראייה מערכתית',
	businessOrientaionDetailsB: 'פירוט',
	// skillsB: 'התרשמות מקצועית - יכולות אנליטיות, נתונים לימודיים, יכולות לימוד, תפיסה מהירה, התמודדות עם מצבי לחץ, אבחנה בין עיקר לטפל, יסודיות, אחריות, התמדה/דבקות במשימה',
	skillsB: 'יכולות מקצועיות  - מוטיבציה, זריזות/ביצועיות, יכולת העמקה, עמידה בלחץ/עומס, למידה ואוטודידקטיות, סקרנות, יכולת העברת מסר',
	skillsDetailsB: 'פירוט',
	// leadershipB: 'כושר מנהיגות - ייצוגיות, זיהוי פוטנציאל ניהולי, כושר שכנוע, ביטחון עצמי, וורבליות, יכולות אסרטיביות',
	leadershipB: 'כושר מנהיגות - עצמאות, חוסן נפשי, פרואקטיביות/הובלה, ייצוגיות, יכולת השפעה, יכולת תכלול, גמישות',
	leadershipDetailsB: 'פירוט',
	// relationshipsB: 'יחסים בינאישיים ועבודה בצוות - יכולת עבודה בצוות, פתיחות וקבלת האחר, יכולת יצירת קשרים בלתי פורמליים, שירותיות, יחסי אנוש, לויאליות יכולת השפעה',
	relationshipsB: 'מערכות יחסים - תקשורת בינאישית, שירותיות, הבנה ארגונית',
	relationshipsDetailsB: 'פירוט',
	// globalOrientaionB: 'אוריינטציה גלובלית - יצירת קשרים גלובליים, שפות, פרספקטיבה גלובלית, עבודה בסביבות מגוונות ו/או משתנות',
	globalOrientaionB: 'אוריינטציה גלובלית - שליטה בשפות, הסתגלות לסביבה משתנה ומגוונת',
	globalOrientaionDetailsB: 'פירוט',

	relevantImpressionsB: 'התרשמות מדגשים רלוונטים - (יציבות, התמודדות עם לחץ, יכולות לימודיות, יכולות אנליטיות,  אוריינטציה עסקית, סקרנות ומוטיבציה, רקע רלוונטי, אוטודידקטיות, יכולת השפעה ללא סמכות, התמודדות עם משימות רוטיניות לאורך זמן, גישה לקבלת מרות, סדר וארגון, כושר מנהיגות, יוזמה חדשנות ויצירתיות)',
};

const RankScale = (props: FieldProps) => {
	return (
		<Stack>
			<FormControl error={!!(props.errorsObj && props.errorsObj[props.fieldKey])} id={props.fieldKey}>
				<FormLabel id={`${props.fieldKey}-radio-buttons-group`}>{props.allTitles && props.allTitles[props.fieldKey]}</FormLabel>

				<RadioGroup sx={{ mt: '20px', alignItems: 'center' }} row aria-labelledby={`${props.fieldKey}-radio-buttons-group`} name={props.fieldKey} value={props.formObj[props.fieldKey as keyof typeof props.formObj]} onChange={props.handleChange}>
					<span style={{ marginLeft: '20px' }}>גבוה</span>
					{[1, 2, 3, 4, 5].map((rank) => (
						<FormControlLabel key={rank} value={rank} control={<Radio sx={{ marginLeft: '20px' }} />} label={rank} />
					))}
					<span style={{ marginRight: '20px' }}>נמוך</span>
				</RadioGroup>
			</FormControl>
			{props.detailsFieldKey && (
				<Box sx={{ maxWidth: '600px' }}>
					<TextField sx={{ my: '10px' }} fullWidth size="small" variant="standard" label={props.allTitles && props.allTitles[props.detailsFieldKey]} name={props.detailsFieldKey} value={props.formObj[props.detailsFieldKey]} onChange={props.handleChange} />
				</Box>
			)}
		</Stack>
	);
};

const RenderField = (props: FieldProps) => {
	let fieldContent;
	switch (props.fieldType) {
		case 'rank': {
			fieldContent = <RankScale {...props} />;
			break;
		}
		case 'text': {
			fieldContent = (
				<Box>
					<TextField error={!!(props.errorsObj && props.errorsObj[props.fieldKey])} label={props.allTitles && props.allTitles[props.fieldKey]} name={props.fieldKey} value={props.formObj && props.formObj[props.fieldKey]} onChange={props.handleChange} id={props.fieldKey} />
				</Box>
			);
			break;
		}
		case 'multiline': {
			fieldContent = (
				<Stack>
					<FormLabel sx={{ mb: '10px' }} error={!!(props.errorsObj && props.errorsObj[props.fieldKey])} id={props.fieldKey}>
						{props.allTitles && props.allTitles[props.fieldKey]}
					</FormLabel>
					<Box sx={{ maxWidth: '600px' }}>
						<TextField error={!!(props.errorsObj && props.errorsObj[props.fieldKey])} fullWidth multiline minRows={4} name={props.fieldKey} value={props.formObj && props.formObj[props.fieldKey]} onChange={props.handleChange} />
					</Box>
				</Stack>
			);
			break;
		}
		default: {
			fieldContent = null;
		}
	}

	return fieldContent;
};

const clientCommands = axios.create({
	baseURL: 'https://niloo-server.herokuapp.com',
	// baseURL: 'http://localhost:3030',
});

function App() {
	// const [isRtl, setIsRtl] = React.useState(true);
	const isRtl = true;

	const [isSent, setIsSent] = React.useState(false);

	const [submitInProgress, setSubmitInProgress] = React.useState(false);

	const [snackbarData, setSnackbarData] = React.useState<snackbarDataProps>({
		open: false,
		severity: 'error',
		msg: '',
	});

	React.useLayoutEffect(() => {
		document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
	}, [isRtl]);

	const [formObj, setFormObj] = React.useState<IFormObj>({
		interviewType: '',
		interviewerName: '',
		applicantName: '',
		applicantNumber: '',

		leadership: '',
		leadershipDetails: '',
		businessOrientaion: '',
		businessOrientaionDetails: '',
		skills: '',
		skillsDetails: '',
		globalOrientaion: '',
		globalOrientaionDetails: '',
		relationships: '',
		relationshipsDetails: '',

		relevantImpressions: '',
		evaluation: '',
		evaluationPros: '',
		evaluationCons: '',
		recommendation: '',
		// isRelated: '',
		isExcel: '',

		englishVocabulary: '',
		englishGrammar: '',

		englishQuestion: '',
		impressionOfInterview: '',

		impressionRank: '',

		specialRequests: '',

		advisoryMatchB: '',
		advisoryMatchDetailsB: '',
		businessOrientaionB: '',
		businessOrientaionDetailsB: '',
		skillsB: '',
		skillsDetailsB: '',
		leadershipB: '',
		leadershipDetailsB: '',
		relationshipsB: '',
		relationshipsDetailsB: '',
		globalOrientaionB: '',
		globalOrientaionDetailsB: '',

		relevantImpressionsB: '',
	});

	const [errorsObj, setErrorsObj] = React.useState<Partial<IFormObj>>({});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		setFormObj({ ...current, [fieldKey]: val });
	};

	const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const val = (event.target as HTMLInputElement).value;
		const fieldKey = (event.target as HTMLInputElement).name;

		const current = formObj;

		// to allow only digits
		const regexTerm = /^[0-9\b]+$/;
		if (val === '' || regexTerm.test(val)) {
			setFormObj({ ...current, [fieldKey]: val });
		}
	};

	const validateAllFields = React.useCallback(() => {
		let errors: any = {};
		let fields: string[] = [
			'interviewType',
			'interviewerName',
			'applicantName',
			'applicantNumber',

			// 'leadership',
			// // 'leadershipDetails',
			// 'businessOrientaion',
			// // 'businessOrientaionDetails',
			// 'skills',
			// // 'skillsDetails',
			// 'globalOrientaion',
			// // 'globalOrientaionDetails',
			// 'relationships',
			// // 'relationshipsDetails',

			// 'relevantImpressions',
		];

		// if (formObj?.interviewType === 'מקצועי') {
		// 	fields.push('evaluationPros', 'evaluationCons', 'recommendation');
		// }
		// if (formObj?.interviewType === 'מש"א') {
		// 	fields.push('isRelated', 'englishVocabulary', 'englishGrammar', 'englishQuestion', 'impressionOfInterview', 'impressionRank', 'specialRequests');
		// }
		if (formObj?.interviewType === 'מקצועי') {
			fields.push(
				'leadership',
				// 'leadershipDetails',
				'businessOrientaion',
				// 'businessOrientaionDetails',
				'skills',
				// 'skillsDetails',
				'globalOrientaion',
				// 'globalOrientaionDetails',
				'relationships',
				// 'relationshipsDetails',

				'relevantImpressions',

				'evaluationPros',
				'evaluationCons',
				'recommendation'
			);
		}
		if (formObj?.interviewType === 'מש"א') {
			fields.push(
				'leadership',
				// 'leadershipDetails',
				'businessOrientaion',
				// 'businessOrientaionDetails',
				'skills',
				// 'skillsDetails',
				'globalOrientaion',
				// 'globalOrientaionDetails',
				'relationships',
				// 'relationshipsDetails',

				'relevantImpressions',

				// 'isRelated',
				'isExcel',
				'englishVocabulary',
				'englishGrammar',
				'englishQuestion',
				'impressionOfInterview',
				'impressionRank',
				'specialRequests'
			);
		}
		if (formObj?.interviewType === 'מקצועי - advisory') {
			fields.push(
				'advisoryMatchB',
				// 'advisoryMatchDetailsB',
				'businessOrientaionB',
				// 'businessOrientaionDetailsB',
				'skillsB',
				// 'skillsDetailsB',
				'leadershipB',
				// 'leadershipDetailsB',
				'relationshipsB',
				// 'relationshipsDetailsB',
				'globalOrientaionB',
				// 'globalOrientaionDetailsB',

				// "relevantImpressionsB",

				'evaluationPros',
				'evaluationCons',
				'recommendation'
			);
		}
		if (formObj?.interviewType === 'מש"א - advisory') {
			fields.push(
				'advisoryMatchB',
				// 'advisoryMatchDetailsB',
				'businessOrientaionB',
				// 'businessOrientaionDetailsB',
				'skillsB',
				// 'skillsDetailsB',
				'leadershipB',
				// 'leadershipDetailsB',
				'relationshipsB',
				// 'relationshipsDetailsB',
				'globalOrientaionB',
				// 'globalOrientaionDetailsB',

				'relevantImpressionsB',

				// 'isRelated',
				'isExcel',
				'impressionRank'
			);
		}
		for (const field of fields) {
			if (!formObj[field as keyof typeof formObj]) {
				errors[field] = 'This field is required.';
			}
		}

		if (isSent) {
			setErrorsObj(errors);
		}

		return errors;
		// return Object.keys(errors).length;
	}, [formObj, isSent]);

	React.useEffect(() => {
		validateAllFields();
	}, [validateAllFields, formObj]);

	const handleSubmitCommands = async () => {
		console.log('handleSubmitCommands');

		setIsSent(true);
		// const errorsCount = validateAllFields();
		const errors = validateAllFields();
		const objKeys = Object.keys(errors);
		const errorsCount = objKeys.length;

		if (errorsCount > 0) {
			console.log('errorsCount', errorsCount, errors);
			const elem = document.querySelector(`#${objKeys[0]}`);
			if (elem) {
				elem.scrollIntoView({ behavior: 'smooth' });
			}
		} else {
			setSubmitInProgress(true);

			try {
				if (formObj?.applicantNumber) {
					const applicant = await sendCommand('find-phone', formObj.applicantNumber);
					console.log(applicant);
					if (applicant?.CardId) {
						const activity = await sendCommand('add-activity', '', applicant.CardId);
						console.log('activity', activity);

						const fileTextResponse = await sendCommand('add-file', '', applicant.CardId);
						console.log('fileTextResponse', fileTextResponse);

						const updateCustom = await sendCommand('update-custom-field', '', applicant.CardId);
						console.log('updateCustom', updateCustom);

						setSnackbarData({ open: true, severity: 'success', msg: 'נשלח בהצלחה' });

						resetForm();
					} else {
						setSnackbarData({ open: true, severity: 'error', msg: 'טלפון נייד של המועמד/ת לא נמצא במערכת' });
					}
				} else {
					console.log('no applicant number');
				}
			} catch (error) {
				console.log(error);
				setSnackbarData({ open: true, severity: 'error', msg: 'אירעה שגיאה' });
			}

			setSubmitInProgress(false);
		}
	};

	const resetForm = () => {
		setFormObj({
			interviewType: '',
			interviewerName: '',
			applicantName: '',
			applicantNumber: '',

			leadership: '',
			leadershipDetails: '',
			businessOrientaion: '',
			businessOrientaionDetails: '',
			skills: '',
			skillsDetails: '',
			globalOrientaion: '',
			globalOrientaionDetails: '',
			relationships: '',
			relationshipsDetails: '',

			relevantImpressions: '',
			evaluation: '',
			evaluationPros: '',
			evaluationCons: '',
			recommendation: '',
			// isRelated: '',
			isExcel: '',

			englishVocabulary: '',
			englishGrammar: '',

			englishQuestion: '',
			impressionOfInterview: '',

			impressionRank: '',

			specialRequests: '',

			advisoryMatchB: '',
			advisoryMatchDetailsB: '',
			businessOrientaionB: '',
			businessOrientaionDetailsB: '',
			skillsB: '',
			skillsDetailsB: '',
			leadershipB: '',
			leadershipDetailsB: '',
			relationshipsB: '',
			relationshipsDetailsB: '',
			globalOrientaionB: '',
			globalOrientaionDetailsB: '',

			relevantImpressionsB: '',
		});

		setIsSent(false);
	};

	const sendCommand = async (cmd: string, phone?: string, cardId?: string) => {
		let result;

		const commandData = {
			cmd,
			phone,
			formObj: JSON.stringify(formObj),
			cardId,
		};

		try {
			let response = await clientCommands.post('/actions', commandData);
			if (response.data) {
				result = response.data;
			} else {
				console.log('no response data');
			}
		} catch (error) {
			console.log(error);
		}

		return result;
	};

	const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarData({ ...snackbarData, open: false });
	};

	return (
		<CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
			<ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
				<CssBaseline />
				<Container sx={{ py: '40px' }}>
					<Stack spacing="30px">
						<Box sx={{ textAlign: 'center' }}>
							<img src={banner2} alt="banner2" style={{ width: '100%' }} />
							<Typography variant="h3" gutterBottom component="div" style={{ padding: '20px' }}>
								<img src={logo} alt="Logo" style={{ marginLeft: 40 }} />
								טופס סיכום ראיון מתמחה
							</Typography>
							<img src={banner2} alt="banner2" style={{ width: '100%' }} />
						</Box>

						<Box>
							<FormControl required error={!!errorsObj?.interviewType}>
								<FormLabel id="interview-type-radio-buttons-group">{allTitles?.interviewType}</FormLabel>
								<RadioGroup row aria-labelledby="interview-type-radio-buttons-group" name="interviewType" value={formObj?.interviewType} onChange={handleChange}>
									<FormControlLabel value="מקצועי" control={<Radio />} label="ראיון מקצועי" />
									<FormControlLabel value='מש"א' control={<Radio />} label='ראיון מש"א' />
									<FormControlLabel value="מקצועי - advisory" control={<Radio />} label="ראיון מקצועי - advisory" />
									<FormControlLabel value='מש"א - advisory' control={<Radio />} label='ראיון מש"א - advisory' />
								</RadioGroup>
							</FormControl>
						</Box>

						<RenderField fieldType="text" fieldKey="interviewerName" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

						<RenderField fieldType="text" fieldKey="applicantName" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

						<RenderField fieldType="text" fieldKey="applicantNumber" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleNumberChange} />

						{(formObj?.interviewType === 'מקצועי' || formObj?.interviewType === 'מש"א') && (
							<>
								<RenderField fieldType="rank" fieldKey="leadership" detailsFieldKey="leadershipDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="businessOrientaion" detailsFieldKey="businessOrientaionDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="skills" detailsFieldKey="skillsDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="globalOrientaion" detailsFieldKey="globalOrientaionDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="relationships" detailsFieldKey="relationshipsDetails" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="multiline" fieldKey="relevantImpressions" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
							</>
						)}
						{(formObj?.interviewType === 'מקצועי - advisory' || formObj?.interviewType === 'מש"א - advisory') && (
							<>
								<RenderField fieldType="rank" fieldKey="advisoryMatchB" detailsFieldKey="advisoryMatchDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="businessOrientaionB" detailsFieldKey="businessOrientaionDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="skillsB" detailsFieldKey="skillsDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="leadershipB" detailsFieldKey="leadershipDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="relationshipsB" detailsFieldKey="relationshipsDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
								<RenderField fieldType="rank" fieldKey="globalOrientaionB" detailsFieldKey="globalOrientaionDetailsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								{/* <RenderField fieldType="multiline" fieldKey="relevantImpressionsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} /> */}
							</>
						)}

						{(formObj?.interviewType === 'מקצועי' || formObj?.interviewType === 'מקצועי - advisory') && (
							<>
								<Box>
									<Typography variant="subtitle1" gutterBottom>
										{allTitles?.evaluation}
									</Typography>
									<Stack spacing="10px" sx={{ maxWidth: '600px' }}>
										<TextField error={!!errorsObj?.evaluationPros} label={allTitles?.evaluationPros} fullWidth name="evaluationPros" value={formObj?.evaluationPros} onChange={handleChange} />
										<TextField error={!!errorsObj?.evaluationCons} label={allTitles?.evaluationCons} fullWidth name="evaluationCons" value={formObj?.evaluationCons} onChange={handleChange} />
									</Stack>
								</Box>

								<Box>
									<FormControl error={!!errorsObj?.recommendation}>
										<FormLabel id="recommendation-radio-buttons-group">{allTitles?.recommendation}</FormLabel>
										<RadioGroup aria-labelledby="recommendation-radio-buttons-group" name="recommendation" value={formObj?.recommendation} onChange={handleChange}>
											<FormControlLabel value="ממליץ" control={<Radio />} label="ממליץ" />
											<FormControlLabel value="מתלבט" control={<Radio />} label="מתלבט" />
											<FormControlLabel value="לא ממליץ" control={<Radio />} label="לא ממליץ" />
										</RadioGroup>
									</FormControl>
								</Box>
							</>
						)}

						{formObj?.interviewType === 'מש"א' && (
							<>
								{/* <Box>
									<FormControl error={!!errorsObj?.isRelated}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isRelated}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isRelated" value={formObj?.isRelated} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box> */}
								<Box>
									<FormControl error={!!errorsObj?.isExcel}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isExcel}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isExcel" value={formObj?.isExcel} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box>

								<RenderField fieldType="rank" fieldKey="englishVocabulary" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="englishGrammar" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="multiline" fieldKey="englishQuestion" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="multiline" fieldKey="impressionOfInterview" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="rank" fieldKey="impressionRank" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								<RenderField fieldType="multiline" fieldKey="specialRequests" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
							</>
						)}

						{formObj?.interviewType === 'מש"א - advisory' && (
							<>
								<RenderField fieldType="multiline" fieldKey="relevantImpressionsB" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />

								{/* <Box>
									<FormControl error={!!errorsObj?.isRelated}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isRelated}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isRelated" value={formObj?.isRelated} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box> */}
								<Box>
									<FormControl error={!!errorsObj?.isExcel}>
										<FormLabel id="related-radio-buttons-group">{allTitles?.isExcel}</FormLabel>
										<RadioGroup aria-labelledby="related-radio-buttons-group" name="isExcel" value={formObj?.isExcel} onChange={handleChange}>
											<FormControlLabel value="כן" control={<Radio />} label="כן" />
											<FormControlLabel value="לא" control={<Radio />} label="לא" />
										</RadioGroup>
									</FormControl>
								</Box>

								<RenderField fieldType="rank" fieldKey="impressionRank" formObj={formObj} allTitles={allTitles} errorsObj={errorsObj} handleChange={handleChange} />
							</>
						)}

						<Box sx={{ textAlign: 'center' }}>
							<Button sx={{ maxWidth: '600px', my: '40px' }} fullWidth variant="contained" size="large" onClick={handleSubmitCommands} disabled={submitInProgress}>
								{submitInProgress ? <CircularProgress size="27px" /> : 'שליחה'}
							</Button>
						</Box>
					</Stack>
				</Container>

				<Snackbar sx={{ minWidth: '350px' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarData.open} autoHideDuration={8000} onClose={handleSnackbarClose}>
					<Alert onClose={handleSnackbarClose} elevation={6} variant="filled" severity={snackbarData.severity} sx={{ width: '100%' }}>
						{snackbarData?.msg}
					</Alert>
				</Snackbar>
			</ThemeProvider>
		</CacheProvider>
	);
}

export default App;
